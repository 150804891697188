<template>
  <div class="rows books">
    <div class="bg-white p-4  my-4 rounded-lg shadow-sm">
      <label>Sales - Smartlib</label>
      <h4 class="text-2xl font-semibold">Sales - Smartlib</h4>
    </div>

    <div class="bg-white p-4 my-4 rounded-lg shadow-sm">
      <div class="flex justify-between">
        <div class="w-full">
          <div class="grid grid-cols-4 gap-4">
            <div>
              <div class="block">
                <span class="font-semibold block">Period Date</span>
                <Calendar class="pt-3 w-full calendar-smartlib" v-model="dates" selectionMode="range" view="month"
                  :manualInput="false" dateFormat="mm/yy" />
              </div>
            </div>
            <div>
              <div class="block">
                <span class="font-semibold block mb-3">Offer Type</span>
                <select id="searchOfferMode" class="rounded-lg py-2.5 border w-full" v-model="offerType"
                  track-by="value" required>
                  <option v-for="offer in offerTypeOptions" :key="offer.value" :value="offer.value">
                    {{ offer.text }}
                  </option>
                </select>
              </div>
            </div>
            <div>
              <div class="block">
                <span class="font-semibold block mb-3">Publisher (Optional)</span>
                <multiselect v-model="publisher" label="name" track-by="name" :disabled="userData.role_id === 5"
                  placeholder="Type Publisher" open-direction="bottom" :options="publisherSearchOptions"
                  :loading="isPublisherSearch" :multiple="true" :searchable="true" :close-on-select="false"
                  @search-change="publisherFind">
                </multiselect>
              </div>
            </div>
            <div class="block mt-9 ml-3">
              <button class="rounded-lg bg-blue-700 py-2.5 px-3 text-white" @click="actionGetReportSmartlib">
                Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="bg-white rounded-lg overflow-hidden">
      <div v-if="isLoading" role="status" class="flex justify-center items-center h-72">
        <svg aria-hidden="true" class="w-8 h-8 text-gray-200 text-center animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor" />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill" />
        </svg>
        <span class="sr-only text-center items-center">Loading...</span>
      </div>
      <div v-if="!isLoading">
        <t-table :data="items" class="shadow-none border border-gray-50 bg-white">
          <template v-slot:thead="props">
            <thead>
              <tr>
                <th :class="props.thClass" class="pl-4 text-left">No</th>
                <th :class="props.thClass">Vendor Name</th>
                <th :class="props.thClass">Periode</th>
                <th :class="props.thClass">Offer Type</th>
                <th :class="props.thClass" class="pr-4"></th>
              </tr>
            </thead>
          </template>
          <template v-if="!items.length" v-slot:tbody="props">
            <tbody :class="props.tbodyClass">
              <tr :class="[props.trClass, 'text-center']">
                <td :class="props.tdClass" colspan="15">
                  <div class="flex-col items-center justify-center flex-nowrap flex h-96">
                    <svg class="w-16 h-16 text-gray-800 dark:text-white" aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                      <path
                        d="M5.05 3C3.291 3 2.352 5.024 3.51 6.317l5.422 6.059v4.874c0 .472.227.917.613 1.2l3.069 2.25c1.01.742 2.454.036 2.454-1.2v-7.124l5.422-6.059C21.647 5.024 20.708 3 18.95 3H5.05Z" />
                    </svg>

                    <h4 class="text-base text-gray-600 mb-0">Select Filter to Display</h4>
                    <p class="text-sm text-gray-500">Your data will be displayed once the filter has been selected.</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
          <template slot="row" slot-scope="props">
            <tr class="border-b dark:bg-gray-800 dark:border-gray-700 bg-54 px-3 hover:bg-gray-50">
              <td :class="props.tdClass" class="pl-4">
                {{ (currentPage - 1) * perPage + props.rowIndex + 1 }}
              </td>
              <td :class="props.tdClass">{{ props.row.vendor_name }}</td>
              <td :class="props.tdClass">{{ formatPeriod(props.row.disbursement_period) }}</td>
              <td :class="props.tdClass">
                <div v-if="props.row.offer_type === 'single'">
                  <span class="bg-blue-100 text-blue-500 p-1 rounded">
                    {{ props.row.offer_type }}
                  </span>
                </div>
                <div v-else-if="props.row.offer_type === 'service'">
                  <span class="bg-red-100 text-red-500 p-1 rounded">
                    {{ props.row.offer_type }}
                  </span>
                </div>
                <div v-else-if="props.row.offer_type === 'subscription'">
                  <span class="bg-purple-100 text-purple-500 p-1 rounded">
                    {{ props.row.offer_type }}
                  </span>
                </div>
              </td>
              <td :class="props.tdClass">
                <div v-if="userData.role_id === 1 || userData.role_id === 14 || userData.role_id === 5">
                  <button :id="`smartlib-download-${props.row.number_invoice}`" @click="
                    downloadSmartlib({
                      offer_type: props.row.offer_type,
                      vendor_id: props.row.vendor_id,
                      period: props.row.disbursement_period,
                      index: props.rowIndex,
                      number_invoice: props.row.number_invoice
                    })
                    " v-if="props.row.alloweddownload"
                    :disabled="buttonLoading[props.rowIndex] || buttonDownloaded[props.rowIndex] || !props.row.is_active">
                    <div
                      v-if="props.row.is_active && !buttonLoading[props.rowIndex] && !buttonDownloaded[props.rowIndex]">
                      <i class="fa fa-download text-gray-500 text-lg"></i>
                    </div>
                    <div v-else-if="!props.row.is_active && props.row.alloweddownload">
                      <i class="fa fa-ban text-gray-300 text-lg"></i> Unavailable
                    </div>
                    <div v-else-if="buttonLoading[props.rowIndex]">
                      <i class="fa fa-spinner fa-spin text-gray-500 text-lg"></i>
                    </div>
                    <div v-else-if="buttonDownloaded[props.rowIndex]">
                      <i class="fa fa-check text-green-500 text-lg"></i>
                    </div>
                    <div v-else>
                    </div>
                  </button>
                </div>

                <div v-if="!props.row.alloweddownload">
                  <i class="fa fa-warning"> </i>
                  Vendor Id Issue
                </div>
              </td>
            </tr>
          </template>
        </t-table>
      </div>
      <div v-if="!isLoading"
        class="px-4 flex bg-white justify-between pl-0 pr-3 pt-4 mb-0 border-t border-gray-100 mt-1">
        <div class="font-semibold">
          <span class="text-gray-500 font-normal">Showing </span> {{ currentPage }}-{{ perPage }} <span
            class="text-gray-500 font-normal">of</span> {{ parseInt(totalRows) }}
        </div>
        <div class="mb-2">
          <t-pagination :total-items="Number(totalRows)" :per-page="perPage" v-model="currentPage"
            @change="onClickPage" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import { ReportInvoiceSinglePath } from '../../router/report';
import constant from '../../store/constant';
export default {
  name: 'manage-report-smartlib',
  data() {
    return {
      dates: null,
      ReportInvoiceSinglePath,
      tooltipText: '',
      userData: JSON.parse(localStorage.getItem('session_auth')),
      indexRow: -1,
      currentPage: 1,
      buttonLoading: [],
      buttonDownloaded: [],
      perPage: 50,
      query_search: '',
      isLoadingSearchBySmartlibNumber: false,
      isLoadingSearchByDisbursementPeriod: false,
      searchMode: '1',
      searchModeOptions: [
        { value: '1', text: 'Search By Number Smartlib' },
        { value: '2', text: 'Search By Disbursement Period' },
      ],
      range: {
        start: moment()
          .startOf('month')
          .subtract(1, 'M')
          .format('YYYY-MM-DD'),
        end: moment()
          .subtract(1, 'M')
          .endOf('month')
          .format('YYYY-MM-DD'),
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
      offerType: 0,
      offerTypeOptions: [
        { value: 0, text: 'All Offer' },
        { value: constant.OFFER_TYPE.SINGLE, text: 'Single' },
        { value: constant.OFFER_TYPE.SUBSCRIPTIONS, text: 'Subscription' },
        { value: constant.OFFER_TYPE.SERVICE, text: 'Service' },
      ],
      debounce: null,
      alertCheckRevenue: null,
      isLoadingCheckRevenue: false,
      publisher: [],
      publisherSearchOptions: [],
      isPublisherSearch: false,
      fields: [
        { key: 'No', label: 'No' },
        {
          key: 'vendor_name',
          label: 'Vendor',
        },
        {
          key: 'number_invoice',
          label: 'Number Smartlib',
        },
        {
          key: 'disbursement_period',
          label: 'Period',
        },
        {
          key: 'offer_type',
          label: 'Offer Type',
        },
        {
          key: 'disbursement',
          label: 'Estimated Revenue'
        },
        { key: 'action_download', label: 'Download' },
        // { key: "action_button", label: "Action" },
      ],
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.smartlibReport.isLoading,
      items: (state) => state.smartlibReport.items ?? [],
      isError: (state) => state.smartlibReport.isError,
      totalRows: (state) => state.smartlibReport.totalRows,
      errorMessage: (state) => state.smartlibReport.errorMessage,
      successMessage: (state) => state.smartlibReport.successMessage,
      selfVendorId: (state) => state.smartlibReport.selfVendorId,
      isDownloadLoading: (state) => state.smartlibReport.isDownloadLoading,
      vendors: (state) => state.vendors.items
    }),
  },
  watch: {
    errorMessage: function () {
      if (!this.errorMessage) return;
      this.messageAlert('error', this.errorMessage, 5000);
    },
    successMessage: function () {
      if (!this.successMessage) return;
      this.messageAlert('success', this.successMessage);
    },
    currentPage: function () {
      this.actionGetReportSmartlib();
    },

    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Sales - Smartlib | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  created() {
    this.publisherFind();
    this.actionGetVendor();
    this.fields = this.fields.filter(field => field.key !== 'disbursement');
  },
  methods: {
    ...mapActions('smartlibReport', [
      'fetchReportSmartlib',
      'downloadReportSmartlibIntoEmailPublisher',
      'getReportSmartlibTotalRevenue',
    ]),
    ...mapActions('vendors', ['fetchVendors', 'searchVendors']),
    onClickPage(page) {
      this.currentPage = page;
    },
    actionGetVendor() {
      let payload = {
        page: 1,
        limit: 1000,
      };
      this.fetchVendors(payload);
      if (this.vendors) {
        this.publisherSearchOptions = this.vendors
      }
    },
    actionGetReportSmartlib() {
      this.buttonDownloaded = []
      if (this.query_search) {
        let payload = {
          q: this.query_search,
          page: this.currentPage,
          limit: this.perPage,
        };
        this.fetchReportSmartlib(payload);
        this.setQuery(payload);
      } else {
        let payload = {
          page: this.currentPage,
          limit: this.perPage,
        };
        if (this.dates) {
          payload.startRange = moment(this.dates[0]).endOf('month').format('YYYY-MM-DD');
          payload.endRange = this.dates[1] ? moment(this.dates[1]).endOf('month').format('YYYY-MM-DD') : moment(this.dates[0]).endOf('month').format('YYYY-MM-DD');
        }
        if (this.offerType) payload.offer = this.offerType;
        if (this.publisher.length) {
          payload.publisher = this.publisher
            ? this.publisher.map((val) => val?.id)
            : '';
        }
        this.fetchReportSmartlib(payload);
        this.setQuery(payload);
      }
    },
    setQuery(q = {}) {
      this.$router.push({ query: q }).catch(() => { });
    },
    setQuerySearch(query) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.query_search = query;
      }, 1000);
    },
    setSearchMode(mode) {
      this.searchMode = mode;
    },
    formatDate(tgl) {
      if (tgl) {
        return moment(tgl).format('DD MMM YY hh:mm');
      }
      return '-';
    },
    formatPeriod(tgl) {
      if (tgl) {
        return moment(tgl).format('D MMMM YYYY');
      }
      return '-';
    },

    asyncFindBySmartlibNumber() {
      this.isLoadingSearchBySmartlibNumber = true;
      clearTimeout(this.debounce);
      this.publisher = [];
      this.offerType = 0;
      this.range.start = moment().format('DD MMM YY');
      this.range.end = moment().format('DD MMM YY');
      this.debounce = setTimeout(() => {
        const query = this.query_search;
        const modeQuery = 1;
        if (!query || !modeQuery) {
          this.actionGetReportSmartlib();
          this.isLoadingSearchBySmartlibNumber = false;
          return;
        }
        this.searchReportSmartlib({
          q: query,
          page: this.currentPage,
          limit: this.perPage,
          mode: modeQuery,
        })
          .then((response) => {
            this.items = response.data.data.rows;
            this.isLoadingSearchBySmartlibNumber = false;
          })
          .catch(() => {
            this.isLoadingSearchBySmartlibNumber = false;
          });
      }, 1000);
    },
    asyncFindByDisbursementPeriod() {
      this.isLoadingSearchByDisbursementPeriod = true;
      this.query_search = null;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        const modeQuery = 2;
        if (!modeQuery) {
          this.actionGetReportSmartlib();
          this.isLoadingSearchByDisbursementPeriod = false;
          return;
        }
        const payload = {
          page: this.currentPage,
          limit: this.perPage,
          mode: modeQuery,
          startRange: this.range.start
            ? new Date(this.range.start).toISOString()
            : null,
          endRange: this.range.end
            ? new Date(this.range.end).toISOString()
            : null,
          offer: this.offerType,
          publisher: this.publisher.map((item) => item.id),
        };
        this.searchReportSmartlib(payload)
          .then((response) => {
            this.items = response.data.data.rows;
            this.isLoadingSearchByDisbursementPeriod = false;
          })
          .catch(() => {
            this.isLoadingSearchByDisbursementPeriod = false;
          });
      }, 1000);
    },
    asyncClearFind() {
      this.query_search = '';
      this.searchMode = '1';
      this.isLoadingSearchBySmartlibNumber = false;
      this.isLoadingSearchByDisbursementPeriod = false;
      this.range = {
        start: moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      };
      this.offerType = 0;
      this.publisher = [];
      this.publisherSearchOptions = [];
      this.actionGetReportSmartlib();
    },
    showModal(numberSmartlib) {
      this.alertCheckRevenue = null;
      this.isLoadingCheckRevenue = false;
      this.$refs[`${numberSmartlib}`].show();
    },
    downloadSmartlib(payload) {
      this.$set(this.buttonLoading, payload.index, true);
      this.tooltipText = this.successMessage || 'Report smartlib berhasil diproses, silakan cek email Anda dalam 5 menit ke depan.';
      setTimeout(() => {
        this.$set(this.buttonLoading, payload.index, false);
        this.$set(this.buttonDownloaded, payload.index, true);
        this.downloadReportSmartlibIntoEmailPublisher(payload);
      }, 8000);
    },
    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: 'true',
        position: 'top-end',
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
    async publisherFind(query) {
      if (this.userData.role_id === 5) {
        try {
          const response = await this.searchVendors({ email: this.userData.email });
          this.publisherSearchOptions = response.data.data.rows;
          this.publisher = this.publisherSearchOptions
        } catch (error) {
          console.error('Error fetching publisher data:', error);
        }
      }

      if (!query) return;
      this.isPublisherSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          q: query,
          limit: 10,
        };
        this.searchVendors(payload)
          .then((response) => {
            this.publisherSearchOptions = response.data.data.rows;
            this.isPublisherSearch = false;
          })
          .catch(() => {
            this.isPublisherSearch = false;
          });
      }, 600);
    },

    clearSmartlibNumber() {
      this.query_search = null;
    },
    formatCurrency(value, currency) {
      if (value) {
        let formatter = null;
        if (currency == 'USD') {
          formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
          });
        } else if (currency == 'SGD') {
          formatter = new Intl.NumberFormat('en-SG', {
            style: 'currency',
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat('en-ID', {
            style: 'currency',
            currency: currency,
          });
        }

        return formatter.format(value);
      }

      return 'In callculated';
    }
  },
};
</script>
<style scoped>
.badge-purple {
  background-color: #ff06dc;
}

.badge-yellow {
  background-color: #ffec2e;
  color: black;
}

:deep(.calendar-smartlib .p-inputtext) {
  border: 1px solid rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  border-radius: 4px !important;
}
</style>